import React, {Component} from 'react'
import { Menu, Image } from 'semantic-ui-react'
import images from '../../Constants/Images'
import NavbarItem from './NavbarItem'
import {connect} from "react-redux";
import {push} from "react-router-redux";

class NavbarMobile extends Component {
    constructor(props){
        super(props);

        this.goToURL = this.goToURL.bind(this);
    }

    goToURL(e){
        e.preventDefault();

        this.props.navigateTo("/")
    }

    render() {
        return (
            <div>
                <Menu secondary stackable>
                    <Menu.Menu position="left">
                        <Menu.Item>
                            <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                <img style={{cursor: 'pointer', height: '50%', width: '50%'}} src={images.logoNav} onClick={this.goToURL} alt="logo" />
                                {/*<Image style={{cursor: 'pointer'}} src={images.logoNav} onClick={this.goToURL} size="medium"/>*/}
                            </div>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    navigateTo: push
};

export default connect(mapStateToProps, mapActionsToProps)(NavbarMobile)