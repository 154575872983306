import React, {Component} from 'react'
import {
    XYPlot,
    XAxis,
    MarkSeries,
    YAxis,
    FlexibleWidthXYPlot,
    VerticalBarSeries,
    Hint
} from 'react-vis';

class ColumnChart extends Component {
    constructor(props){
        super(props);

        this.state = {
            value: null
        }
    }

    _forgetValue = () => {
        this.setState({
            value: null
        });
    };

    _rememberValue = value => {
        this.setState({ value  });
    };

    render() {
        const {value} = this.state;
        const hintStyle = {backgroundColor: "black", border: "1px solid grey", borderRadius: "5px", padding: "10px", opacity: "0.8"}
        return (
            <FlexibleWidthXYPlot
                xType="ordinal"
                height={200}>
                <XAxis hideTicks/>
                <YAxis hideTicks/>
                {
                    this.props.type === "categorical" ?
                        <VerticalBarSeries
                            stroke="lightgrey"
                            data={this.props.vizData}
                            onValueMouseOver={this._rememberValue}
                            onValueMouseOut={this._forgetValue}
                        /> : <MarkSeries
                            data={this.props.vizData}
                            onValueMouseOver={this._rememberValue}
                            onValueMouseOut={this._forgetValue} />
                }
                {value ? <Hint value={value}
                               align={{horizontal: 'left', vertical: "bottom"}}>
                    {
                        this.props.type === "categorical" ?
                            <div style={hintStyle}>
                                <b>{this.props.name}: </b>{value.x}
                                <br/>
                                <b>Count: </b>{value.y}
                            </div>  : <div style={hintStyle}>
                                <b>{this.props.name}: </b>{value.y}
                                <br/>
                                <b>Row: </b>{value.x}
                            </div>
                    }
                </Hint> : null}
            </FlexibleWidthXYPlot>
        )
    }
}

export default ColumnChart