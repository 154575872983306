import React, {Component} from 'react'
import {connect} from 'react-redux'
import FileTable from "../FileTable"
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";
import {modifyFilePK} from "../../Actions/ModifyFilePK";
import { Button, Icon } from 'semantic-ui-react'

class ExploreIndex extends Component {
    constructor(props) {
        super(props);
    }

    changeStep = () => this.props.changeStep(1)

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-8" style={{wordBreak: "break-all"}}>
                        <h1>{this.props.filePK}</h1>
                    </div>
                    <div className="col-sm-4">
                        {
                            this.props.fileInformation.length > 1 ?
                            <Button color="orange" fluid onClick={this.changeStep}>
                                Select Variables to Predict
                                <Icon name="arrow right" />
                            </Button> : null
                        }

                    </div>
                </div>
                <br />
                <FileTable />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation,
    modifyFilePK: modifyFilePK
}

export default connect(mapStateToProps, mapActionsToProps)(ExploreIndex)