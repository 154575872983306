import React, { Component } from 'react';
import NavbarIndex from './SharedComponents/Navbar/NavbarIndex';
import Routes from './Routes';
import withAuthentication from './Authentication/withAuthentication';
import FooterIndex from './SharedComponents/Footer/FooterIndex'
import FeedbackModal from './SharedComponents/Feedback/FeedbackModal'
import LoadingModal from './SharedComponents/LoadingModal'
import SignUpModal from "./Authentication/UserPages/SignUp/SignUpModal";
import LoginModal from "./Authentication/UserPages/LogIn/LoginModal";
import ForgetPasswordModal from "./Authentication/UserPages/Password/ForgetPasswordModal";
import ProfileModal from "./InfoPages/Profile/ProfileModal";
import ContactUsModal from "./InfoPages/ContactUs/ContactUsModal";
import SampleDataModal from "./Index/SampleDataModal"
import EditFileOptionsModal from "./Index/EditFileOptions/EditFileOptionsModal"
import SupportModal from "./SharedComponents/SupportModal/SupportModal";
import AdditionalParamModal from "./NewModel/AlgorithmParameters/AdditionalParamModal"

class AppAuthentication extends Component {
    render() {
        return (
            <div>
                <AdditionalParamModal />
                <SupportModal/>
                <EditFileOptionsModal/>
                <SampleDataModal />
                <FeedbackModal/>
                <LoadingModal name="loadingModalDownloadFile" header="Splitting and Downloading Your Files" message="Splitting and Downloading Your Files..." noClose/>
                <SignUpModal/>
                <LoginModal/>
                <ForgetPasswordModal/>
                <ProfileModal/>


                <ContactUsModal/>
                <div id="appAuthentication">
                    <NavbarIndex/>
                    <Routes/>
                    <FooterIndex/>
                </div>
            </div>
        );
    }
}


export default withAuthentication(AppAuthentication)
