import React, {Component} from 'react'
import { Step, Icon } from 'semantic-ui-react'

class NewModelIndexSteps extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Step.Group fluid>
                    <Step active={this.props.step === 0} completed={this.props.step > 0}>
                        <Icon name="chart bar outline" />
                        <Step.Content>
                            <Step.Title>Explore</Step.Title>
                            {/*<Step.Description>Visualize and Explore your Data</Step.Description>*/}
                        </Step.Content>
                    </Step>
                    <Step active={this.props.step === 1} completed={this.props.step > 1}>
                        <Icon name="columns" />
                        <Step.Content>
                            <Step.Title>Select</Step.Title>
                            {/*<Step.Description>Choose Input & Prediction Variables</Step.Description>*/}
                        </Step.Content>
                    </Step>
                    <Step active={this.props.step === 2} completed={this.props.step > 2}>
                        <Icon name="cogs" />
                        <Step.Content>
                            <Step.Title>Train</Step.Title>
                            {/*<Step.Description>Select Parameters & Train Model</Step.Description>*/}
                        </Step.Content>
                    </Step>
                    <Step active={this.props.step === 3} completed={this.props.step > 3}>
                        <Icon name="magnify" />
                        <Step.Content>
                            <Step.Title>Assess</Step.Title>
                            {/*<Step.Description>Assess Model for Accuracy & Save</Step.Description>*/}
                        </Step.Content>
                    </Step>
                </Step.Group>
            </div>
        )
    }
}

export default NewModelIndexSteps