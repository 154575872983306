export const getColumnStats = (columnValues) => {
    let columnType = getColumnType(columnValues)
    let xLabel = [];
    let yLabel = [];
    let missingValuesCount = 0;
    let uniqueValues = 0;
    if (columnType === "categorical"){
        for (let i=0; i<columnValues.length; i++){
            if (!xLabel.includes(columnValues[i])){
                xLabel.push(columnValues[i])
                yLabel.push(1)
            } else {
                let xIndex = xLabel.indexOf(columnValues[i])
                yLabel[xIndex] = yLabel[xIndex] + 1
            }
        }

        let missingValuesCountArray = (xLabel.filter(x => x !== null).filter(x => x.toString().trim() === "")).map(x => yLabel[xLabel.indexOf(x)]);
        for (let i=0; i<missingValuesCountArray.length; i++){
            missingValuesCount += missingValuesCountArray[i]
        }

        uniqueValues = xLabel.length;
    } else {
        for (let i=0; i<columnValues.length; i++){
            if (!yLabel.includes(columnValues[i])){
                uniqueValues += 1
            }

            xLabel.push(i+1)
            yLabel.push(columnValues[i])
        }
    }

    const vizData = xLabel.map((x, idx) => { return {x: x, y: yLabel[idx]} })


    return {
        type: columnType,
        unique: uniqueValues,
        missing: missingValuesCount,
        vizData: vizData,
        total: columnValues.length
    }
}

const getColumnType = (columnValues) => {
    let numericalCount = 0;
    for (let i=0; i<columnValues.length; i++){
        if (!isNaN(columnValues[i]) ){
            numericalCount += 1
        }
    }

    if (numericalCount === columnValues.length){
        return "numerical"
    } else {
        return "categorical"
    }
}
