import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react';

class UploadIndicator extends Component {
    render() {
        return (
            <div>
                <span className="uploadIcons">
                    <Icon name="file excel outline" />
                    <Icon name="file alternate outline" />
                </span>
                <h4 className="thinHeading">Select File to Start Building Models</h4>
            </div>
        )
    }
}

export default UploadIndicator