import React, {Component} from 'react'
import { Icon, Button } from 'semantic-ui-react'
import {toggleModal} from "../Actions/ToggleModal";
import { connect } from 'react-redux'
import getCurrencyAmount from "../Constants/getCurrencyAmount";
import pricing from "../Constants/pricing";
import {hasUserMergedWithinTimeframe} from "../Constants/hasUserMergedWithinTimeframe";

class ProBlockMessage extends Component {
    constructor(props){
        super(props);

        this.state = {
            showMessage: true
        }

        this.signUpAndPay = this.signUpAndPay.bind(this);
        this.logIn = this.logIn.bind(this);
    }

    signUpAndPay(e){
        this.props.toggleModal("signUpModal");
    }

    logIn(e){
        this.props.toggleModal("loginModal");
    }

    render() {
        let heading = "";
        let size = this.props.fileInformation.size > pricing.mbMax;
        let time = hasUserMergedWithinTimeframe();

        if (size){
            heading = "Your file is more than 5 MB"
        } else if (time){
            heading = "You have merged within the last " + Math.ceil(pricing.minutesElapsed / 60).toString() + " hour"
        } else if (this.props.proBlockType === "timeFrame"){
            heading = "Your files > 5MB & You have merged within the last " + Math.ceil(pricing.minutesElapsed / 60).toString() + " hour"
        }

        return (
            <div style={{borderRadius: "8px 8px 8px 8px",
                border: "1px lightgrey solid"}}>
                <div className="proBlockMessageWarning">
                    <div style={{fontWeight: "300", textAlign: "center"}}>
                        <h3 style={{fontSize: "125%"}}><Icon name="warning sign" /> {heading}</h3>
                    </div>
                </div>
                <div style={{textAlign: "center", paddingTop: "20px"}}>
                    <Button color="purple" onClick={this.signUpAndPay}
                            style={{height: '90%'}}>
                        <span style={{fontSize: "125%"}}>
                            {"Upgrade to Pro for just " + getCurrencyAmount(this.props.currency) + " per month!"}
                            <span role="img" aria-label="smile" style={{paddingLeft: '7px'}}>😀</span>
                        </span>
                    </Button>
                </div>
                <p style={{padding: "10px", textAlign: "center"}}>As a Pro Member you also get access to our other tool, <a href="https://www.mergespreadsheets.com/" target="_blank" rel="noopener noreferrer">Merge Spreadsheets</a>!</p>
                <div style={{paddingBottom: "20px", textAlign: "center"}}>
                    <p className="linkStyle" onClick={this.logIn}>Already a Pro Member? Log In</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency,
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(ProBlockMessage)