import React, {Component} from 'react'

class HelpIndex extends Component {
    render() {
        return (
            <div style={{minHeight: "100vh"}}>
                <div id="aboutHero">
                    <h1>FAQs</h1>
                    <p>If you have any questions not addressed here, you can <a href="mailto:info@splitspreadsheets.com?Subject=Question%20with%20Split%20Spreadsheets" style={{color: 'lightblue'}}>email us</a></p>
                </div>

            </div>
        )
    }
}

export default HelpIndex