import React, {Component} from 'react'
import { Label, Icon } from 'semantic-ui-react';
import constants from "../../Constants/constants";

class FileRules extends Component {
    render() {
        return (
            <div style={{textAlign: 'center', border: "1px solid lightgrey", borderRadius: "2px", padding: "5px", marginTop: "15px"}}>
                <span><b>File Rules</b></span><br/>
                <Label>
                    Extensions
                    <Label.Detail>{constants.extensions.join(", ")}</Label.Detail>
                </Label>
                <Label color="olive">
                    <Icon name="file alternate outline" />
                    CSV
                    <Label.Detail>Comma Only (,)</Label.Detail>
                </Label>
                <Label color="green">
                    <Icon name="file excel outline" />
                    Excel
                    <Label.Detail>First Sheet Read</Label.Detail>
                </Label>
                <Label color="teal">
                    <Icon name="table" />
                    Rows
                    <Label.Detail>1000 Rows Max</Label.Detail>
                </Label>
                {/*<Label color="blue">*/}
                    {/*<Icon name="columns" />*/}
                    {/*Columns*/}
                    {/*<Label.Detail>15 Columns Max</Label.Detail>*/}
                {/*</Label>*/}
                <Label color="blue">
                    <Icon name="columns" />
                    Columns
                    <Label.Detail>First Row is Column Names</Label.Detail>
                </Label>
            </div>
        )
    }
}

export default FileRules