import React, {Component} from 'react'
import ColumnChart from "./ColumnChart";
import { Label, Icon } from 'semantic-ui-react'

class ColumnInfo extends Component {
    render() {
        return (
                <div>
                    <Label color={this.props.info.type === "categorical" ? "blue" : "green"}>
                        <Icon name={this.props.info.type === "categorical" ? "amilia" : "list ol"} />
                        {this.props.info.type.charAt(0).toUpperCase() + this.props.info.type.substring(1, this.props.info.type.length)}
                    </Label>
                    <Label color="yellow">
                        Total <Label.Detail>{this.props.info.total}</Label.Detail>
                    </Label>
                    <Label color="orange">
                        Unique <Label.Detail>{this.props.info.unique}</Label.Detail>
                    </Label>
                    <Label color="red">
                        Missing <Label.Detail>{this.props.info.missing}</Label.Detail>
                    </Label>
                    <br/><br/>
                    <ColumnChart vizData={this.props.info.vizData}
                                 yRange={this.props.info.yRange}
                                 type={this.props.info.type}
                                 name={this.props.name}
                    />

                </div>
        )
    }
}

export default ColumnInfo