import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Icon, Input, Radio, Dropdown } from 'semantic-ui-react'
import {modifyTestSplit} from "../../Actions/ModifyTestSplit";
import {modifyAlgorithm} from "../../Actions/ModifyAlgorithm";
import {modifyPredictionType} from "../../Actions/modifyPredictionType";
import {capitalizeCamelCase} from "../../Constants/capitalizeCamelCase";
import {modifyAlgorithmParams} from "../../Actions/ModifyAlgorithmParams";
import NeuralNetworkParameters from "./AlgorithmParameters/NeuralNetworkParameters"

class TrainIndex extends Component {
    constructor(props) {
        super(props);
    }

    changeStep = (step) => {
        this.props.changeStep(step)
    }

    changeTestData = (e) => {
        this.props.modifyTestSplit(e.target.value)
    }

    handlePredictionTypeChange = (e, { value }) => {
        const currHyperParams = JSON.parse(JSON.stringify(this.props.mlHyperParameters))
        if (value === "classification"){
            currHyperParams['loss'] = 'categoricalCrossentropy'
        } else {
            currHyperParams['loss'] = 'mse'
        }

        this.props.modifyPredictionType(value)
        this.props.modifyAlgorithmParams(currHyperParams)
    }

    isTestDataError = () => {
        let error = false;

        if (!isNaN(this.props.testSplit)){
            if (this.props.testSplit > 50 || this.props.testSplit < 10){
                error = true
            }
        } else {
            error = true
        }

        return error
    }

    handleAlgorithmChange = (e, { value }) => {
        this.props.modifyAlgorithm(value)
    }

    handleOptimizerChange = (e, { value }) => {
        const currHyperParams = JSON.parse(JSON.stringify(this.props.mlHyperParameters))
        currHyperParams['optimizer'] = value

        this.props.modifyAlgorithmParams(currHyperParams)
    }

    render() {
        const parameterBox = {border: "1px solid lightgrey",
            borderRadius: "15px", padding: "15px", margin: "10px"}

        const allAlgorithms = ["neuralNetwork"];
        const dropdownOptions = allAlgorithms.map((x, idx) => { return { key: idx, value: x, text: capitalizeCamelCase(x) } })

        const parameters = {
            neuralNetwork: null
        }

        return (
            <div>
                <div className="row">
                    <div className="col-sm-3">
                        <Button color="yellow"
                                fluid
                                icon="left arrow"
                                content="Re-select Variables"
                                onClick={(e) => this.changeStep(1)}/>
                    </div>
                    <div className="col-sm-6">
                        <h4 style={{textAlign: "center"}}>Predicting <i style={{fontSize: "150%"}}>{this.props.fileInformation[0][this.props.predictColumnIdx]}</i></h4>
                    </div>
                    <div className="col-sm-3">
                        <Button color="orange"
                                fluid
                                onClick={e => this.changeStep(3)}>
                            Train & Assess Model
                            <Icon name="arrow right" />
                        </Button>
                    </div>
                </div>
                <br/><br/>
                <h4 className="text-muted">Select Options to Train</h4>
                <div className="row">
                    <div className="col-sm-4">
                        <div style={parameterBox}>
                            <h4>Prediction Type</h4>
                            <Radio
                                label='Classification'
                                name='predictionType'
                                value='classification'
                                checked={this.props.predictionType === 'classification'}
                                onChange={this.handlePredictionTypeChange}
                            />
                            <br/>
                            <Radio
                                label='Regression'
                                name='predictionType'
                                value='regression'
                                checked={this.props.predictionType === 'regression'}
                                onChange={this.handlePredictionTypeChange}
                            />
                        </div>
                        <br/>
                        <div style={parameterBox}>
                            <h4>Test Data Split (%)</h4>
                            <Input  type="number"
                                    icon="percent"
                                    invalid={this.isTestDataError()}
                                    onChange={this.changeTestData}
                                    value={this.props.testSplit}
                            />
                            {this.isTestDataError() ? <div><small style={{color: "red"}}>Split must be between 10-50</small></div> : null}
                        </div>
                    </div>
                    <div className="col-sm-8">
                        <div style={parameterBox}>
                            <h4>Algorithm</h4>
                            <Dropdown
                                fluid
                                options={dropdownOptions}
                                value={this.props.mlAlgorithm}
                                selection
                                onChange={this.handleAlgorithmChange}
                                placeholder="Select Algorithm"
                            />
                            <br/>
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4>Optimizer</h4>
                                    <Radio
                                        label='ADAM'
                                        name='optimizer'
                                        value='adam'
                                        checked={this.props.mlHyperParameters.optimizer === 'adam'}
                                        onChange={this.handleOptimizerChange}
                                    />
                                    <br/>
                                    <Radio
                                        label='Stochastic Gradient Descent'
                                        name='optimizer'
                                        value='sgd'
                                        checked={this.props.mlHyperParameters.optimizer === 'sgd'}
                                        onChange={this.handleOptimizerChange}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <h4>Loss Function</h4>
                                    <Radio
                                        label='Cross Entropy'
                                        name='loss'
                                        value='categoricalCrossentropy'
                                        readOnly={true}
                                        checked={this.props.mlHyperParameters.loss === 'categoricalCrossentropy'}
                                    />
                                    <br/>
                                    <Radio
                                        label='Mean Squared Error'
                                        name='loss'
                                        value='mse'
                                        readOnly={true}
                                        checked={this.props.mlHyperParameters.loss === 'mse'}
                                    />
                                </div>
                            </div>
                            <br />
                            {parameters[this.props.mlAlgorithm]}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    testSplit: state.mainState.testSplit,
    predictColumnIdx: state.mainState.predictColumnIdx,
    fileInformation: state.mainState.fileInformation,
    mlAlgorithm: state.mainState.mlAlgorithm,
    predictionType: state.mainState.predictionType,
    mlHyperParameters: state.mainState.mlHyperParameters
})

const mapActionsToProps = {
    modifyTestSplit: modifyTestSplit,
    modifyAlgorithm: modifyAlgorithm,
    modifyPredictionType: modifyPredictionType,
    modifyAlgorithmParams: modifyAlgorithmParams
}

export default connect(mapStateToProps, mapActionsToProps)(TrainIndex)