import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Input} from 'semantic-ui-react'

class ParameterInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            errorMessage: null
        };

        this.updateValue = this.updateValue.bind(this);
        this.checkValue = this.checkValue.bind(this);
    }

    updateValue(event){
        this.checkValue(event.target.value);
        this.props.onUpdateValue(event.target.name, event.target.value);
    }

    checkValue(value){
        let error = false;
        let errorMessage = null;
        if (inArray("string", this.props.types) && (inArray("int", this.props.types) || inArray("float", this.props.types))){
            if (!inArray(value.toLowerCase(), this.props.restrictions["string"]) ){
                const defaultValues = this.props.restrictions["string"].join(", ");
                error = true ;
                errorMessage = "Please choose one of the following values: " + defaultValues;
            }
        }


    }
    // props:
    // type: [int, float, string, null] , if null display a clear all
    // restrictions : {
    // int: [min, max],
    // float: [min, max],
    // string: [option1, option2, ....]
    // }
    // info: "this does blah blah"
    // onUpdateValue: this.updateParameterInput
    // name: "max_depth"
    // value: val

    render() {
        return (
            <div>
                <Input  name={this.props.name}
                        onChange={this.updateValue}
                        value={this.props.paramValue} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(ParameterInput)

function inArray(value, array){
    let inArray = false;

    if (array.length > 0){
        for (let i=0; i<array.length; i++){
            if (value === array[i]){
                inArray = true;
                break;
            }
        }
    }

    return inArray
}