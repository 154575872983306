import React, {Component} from 'react'
import {Menu, Image, Icon, Button} from 'semantic-ui-react'
import images from '../../Constants/Images'
import NavbarItem from './NavbarItem'
import NavbarLogIn from './NavbarLogIn'
import NavbarSignUp from './NavbarSignUp'
import {connect} from "react-redux";
import {push} from "react-router-redux";
import {BrowserView, MobileView, isBrowser, isMobile} from 'react-device-detect'
import getURLParam from "../../Constants/getURLParam";


class NavbarNonAuth extends Component {
    constructor(props) {
        super(props);

        this.state = {
            urlEnd: '',
            mobileView: false
        };

        this.goToURL = this.goToURL.bind(this);
        this.changeURLEnd = this.changeURLEnd.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    changeURLEnd(changedURLEnd) {
        this.setState({
            urlEnd: changedURLEnd
        })
    }

    goToURL(e) {
        e.preventDefault();
        this.setState({
            urlEnd: ''
        })
        this.props.navigateTo("/")
    }

    toggle(e, state) {
        this.setState({
            mobileView: state
        })
    }

    render() {
        let icon;
        let displayStyle = null;

        if (isMobile) {
            if (this.state.mobileView) {
                icon = <Icon name="close" style={{marginTop: '5px', cursor: 'pointer'}}
                             onClick={(e) => this.toggle(e, false)}/>
                displayStyle = null;
            } else {
                icon = <Icon style={{cursor: 'pointer'}} name="bars" onClick={(e) => this.toggle(e, true)}/>
                displayStyle = {display: 'none'}
            }
        }
        const urlParam = getURLParam(window.location.href);
        if (urlParam.includes("signup") || urlParam.includes("login") || window.location.href.includes("update-payment-info") || urlParam.includes("?team=")) {
            return (
                null
            )
        } else {

            return (
                <div>
                    <MobileView>
                        <div style={{marginTop: '7px', paddingLeft: '5px', paddingRight: '5px'}}>
                            <img style={{cursor: 'pointer', height: '50%', width: '50%'}} src={images.logoNav}
                                 onClick={this.goToURL} alt="logo"/>
                            <span style={{float: 'right'}}>{icon}</span>
                        </div>
                    </MobileView>
                    <Menu secondary stackable style={displayStyle}>
                        <Menu.Menu position="left">
                            <BrowserView>
                                <Menu.Item>
                                    <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                        <Image style={{cursor: 'pointer'}} src={images.logoNav} onClick={this.goToURL}
                                               size="small"/>
                                    </div>
                                </Menu.Item>
                            </BrowserView>
                            <Menu.Item>
                                <small style={{marginTop: '15px'}}>
                                    <a
                                        href="http://www.lovespreadsheets.com/" target="_blank"
                                        rel="noopener noreferrer">
                                        A <span role="img" aria-label="heart">❤️</span> Love Spreadsheets product
                                    </a>
                                </small>
                            </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu position="right" stackable>
                            {/*<Menu.Item>*/}
                            {/*<NavbarItem to="pricing" name="Pricing" color="black" urlEnd={this.state.urlEnd}*/}
                            {/*changeURLEnd={this.changeURLEnd} />*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item>*/}
                            {/*<NavbarItem to="FAQs"*/}
                            {/*name="FAQs"*/}
                            {/*color="black"*/}
                            {/*urlEnd={this.state.urlEnd}*/}
                            {/*changeURLEnd={this.changeURLEnd}/>*/}
                            {/*</Menu.Item>*/}
                            <Menu.Item>
                                <NavbarItem to="datasets" name="Datasets" color="black"/>
                            </Menu.Item>
                            <Menu.Item>
                                <Button color="brown">
                                <NavbarItem to="contactUsModal" name="Contact Us" color="white" modal/>
                                </Button>
                            </Menu.Item>
                            {/*<Menu.Item>*/}
                            {/*<NavbarItem to="feedbackModal" name="Feedback" color="gray" modal auth/>*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item>*/}
                            {/*<NavbarLogIn/>*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item>*/}
                            {/*<NavbarSignUp />*/}
                            {/*</Menu.Item>*/}
                        </Menu.Menu>
                    </Menu>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    navigateTo: push
};

export default connect(mapStateToProps, mapActionsToProps)(NavbarNonAuth)