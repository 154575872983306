import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyAlgorithmParams} from "../../../Actions/ModifyAlgorithmParams";
import { Input } from 'semantic-ui-react'

class NeuralNetworkParameters extends Component {
    constructor(props) {
        super(props);
    }

    handleInputChange = (e) => {
        const hyperParams = JSON.parse(JSON.stringify(this.props.mlHyperParameters))
        hyperParams['neuralNetwork'][e.target.name] = e.target.value

        this.props.modifyAlgorithmParams(hyperParams)
    }

    isInputError = (inputName) => {
        let value = 5;
        if (inputName === "layers"){
            value = this.props.mlHyperParameters.neuralNetwork.layers
        } else if (inputName === "units"){
            value = this.props.mlHyperParameters.neuralNetwork.units
        }

        let error = false;
        if (isNaN(value)){
            error = true
        } else {
            if (value < 1){
                error = true;
            } else {
                if (value.toString().includes(".")){
                    error = true
                }
            }
        }

        return error
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-6">
                    <h4>Neural Network Layers</h4>
                    <Input
                        type="number"
                        invalid={this.isInputError("layers")}
                        name="layers"
                        value={this.props.mlHyperParameters.neuralNetwork.layers}
                        onChange={this.handleInputChange}
                    />
                    { this.isInputError("layers") ? <div>
                        <small style={{color: 'red'}}>Layers must a whole number 1 or greater</small>
                    </div> : null }
                </div>
                <div className="col-sm-6">
                    <h4>Neurons in Each Layer</h4>
                    <Input
                        type="number"
                        invalid={this.isInputError("units")}
                        name="units"
                        value={this.props.mlHyperParameters.neuralNetwork.units}
                        onChange={this.handleInputChange}
                    />
                    { this.isInputError("units") ? <div>
                        <small style={{color: 'red'}}>Units must a whole number 1 or greater</small>
                    </div> : null }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    mlHyperParameters: state.mainState.mlHyperParameters
})

const mapActionsToProps = {
    modifyAlgorithmParams: modifyAlgorithmParams
}

export default connect(mapStateToProps, mapActionsToProps)(NeuralNetworkParameters)