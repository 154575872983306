import React, {Component} from 'react'
import {connect} from 'react-redux'
import UploadFilesIndex from './UploadFiles/UploadFilesIndex'
import FileOptions from "./FileOptions"
import DownloadSplitButton from "./DownloadSplits/DownloadSplitButton"
import ProductQualityFeature from './ProductQualityFeature'
import FAQBlock from "./FAQBlock";
import {modifyCurrency} from "../Actions/ModifyCurrency";
import getCurrency from "../Constants/getCurrency"
import NewModelIndex from "./NewModel/NewModelIndex"
import FileRules from "./UploadFiles/FileRules";
import FileTable from "./FileTable"
import ModelSelector from "./ModelSelector"
import PredictModelIndex from "./PredictModel/PredictModelIndex";
import { Button } from 'semantic-ui-react'

class AppIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            newModel: true
        }
    }

    changeNewModel = (newModel) => {
        this.setState({ newModel: newModel })
    }

    componentDidMount(){
        (function(){
            const s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/60109671c31c9117cb72c7b4/1et0cnbfc';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
        })();
    }

    render() {
        let body = null;

        if (this.state.newModel){
            if (this.props.fileInformation === null){
                body = <div>
                        <UploadFilesIndex />
                        <FileRules/>
                        {/*<div style={{textAlign: "center", marginTop: "100px"}}>*/}
                            {/*<Button icon="line graph"*/}
                                    {/*onClick={e => this.changeNewModel(false)}*/}
                                    {/*size="huge" style={{backgroundColor: "#0081ff", color: "white"}}*/}
                                    {/*content="I Want To Predict From My Model!" />*/}
                        {/*</div>*/}
                    </div>
            } else {
                body = <div>
                    <NewModelIndex/>
                </div>
            }
        } else {
            body = <div>
                <PredictModelIndex/>
            </div>
        }

        return (
            <div className="app">
                    <div className="row" style={{marginTop: "25px"}}>
                        <div className="col-sm-1" />
                        <div className="col-sm-10">
                            {body}
                        </div>
                        <div className="col-sm-1" />
                    </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    modifyCurrency: modifyCurrency
}

export default connect(mapStateToProps, mapActionsToProps)(AppIndex)