import React, {Component} from 'react'
import {connect} from 'react-redux'
import {reshapeData} from "../Constants/reshapeData";
import {getColumnStats} from "../Constants/getColumnStats";
import ColumnInfo from "./ColumnInfo";

const width = {width: "250px", maxWidth: "250px", minWidth: "250px", wordBreak: "break-all"}
const smallWidth = {width: "20px"}

class FileTable extends Component {
    render() {
        if (this.props.fileInformation.length >= 1){
            let fileBody = <h3 style={{color: 'red'}}>Your File was blank. Please try again.</h3>;
            let columnInfoCells = null;
            if (this.props.fileInformation.length >= 2){
                let fileRows = [];

                for (let i=1; i<this.props.fileInformation.length; i++){
                    let fileCols = [<th scope="row" style={smallWidth}>{i}</th>];

                    for (let j=0; j<this.props.fileInformation[i].length; j++){
                        fileCols.push(<td style={width} key={i.toString() + j.toString()}>{this.props.fileInformation[i][j]}</td>)
                    }

                    fileRows.push(<tr key={i.toString()}>{fileCols}</tr>)
                }

                const columnReshaped = reshapeData(this.props.fileInformation.slice(1, this.props.fileInformation.length), true);
                columnInfoCells = columnReshaped.map((x, idx) => <td style={width} key={idx.toString()}><ColumnInfo name={this.props.fileInformation[0][idx]} info={getColumnStats(x)}/></td>)
                columnInfoCells.unshift(<td style={smallWidth}> </td>)

                fileBody =
                <tbody>
                    <tr>
                        {columnInfoCells}
                    </tr>
                    {fileRows}
                </tbody>
            }

            return (
                <div>
                    <div style={{overflowX: "scroll", overflowY: "scroll", height: "600px"}}>
                        <table id="fileTable">
                            <thead style={{backgroundColor: "black", color: "white"}}>
                                <tr>
                                    <th scope="col" style={smallWidth}> </th>
                                    {this.props.fileInformation[0].map((x, idx) => <th style={width} scope="col" key={idx.toString()}>{x}</th>)}
                                </tr>

                            </thead>
                            {fileBody}
                        </table>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <h3 style={{color: 'red'}}>Your File was blank. Please try again.</h3>
                </div>
            )
        }

    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FileTable)