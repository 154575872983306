import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {modifyFilePK} from "../Actions/ModifyFilePK";

class ResetButton extends Component {
    constructor(props) {
        super(props);
    }

    reset = () => {
        this.props.modifyFileInformation(null)
        this.props.modifyFilePK(null)
    }

    render() {
        if (this.props.fileInformation !== null){
            return (
                <Button color="yellow" icon="redo" content="Restart" onClick={this.reset} basic/>
            )
        } else {
            return (
                null
            )
        }

    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation,
    modifyFilePK: modifyFilePK
}

export default connect(mapStateToProps, mapActionsToProps)(ResetButton)