import React, {Component} from 'react'
import { isMobile } from 'react-device-detect'
import ResetButton from "./ResetButton"

class LandingPageHero extends Component {
    render() {
        let textStyle = {textAlign: 'center', paddingBottom: '20px'};

        if (isMobile === true){
            textStyle = {textAlign: 'center', padding: '10px'};
        }

        return (
            <section className="heroSection">
                <div className="row">
                    <div className="col-sm-1" />
                    <div className="col-sm-2">
                        <ResetButton/>
                    </div>
                    <div className="col-sm-6">
                        <div style={{textAlign: "center"}}>
                            <h1 className="thinHeading">ML Spreadsheets</h1>
                        </div>
                    </div>
                    <div className="col-sm-3">
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div style={textStyle}>
                            <h4 className="thinHeading text-muted">Learn about Machine Learning by Creating Models with your Spreadsheets</h4>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default LandingPageHero