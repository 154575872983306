import React, {Component} from 'react'
import capitalizeHyphenated from "../../Constants/capitalizeHyphenated";
import {Label} from 'semantic-ui-react'
import DatasetDownload from "./DatasetDownload";

class DatasetRow extends Component {
    render() {
        return (
            <div className="datasetRow">
                <div className="row">
                    <div className="col-sm-2">
                        <div style={{textAlign: 'center', padding: '15px 0 30px 0'}}>
                            <span style={{fontSize: '200%'}}>{this.props.info.downloads}</span>
                            <br/>
                            <span><b>Downloads</b></span>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div style={{padding: "15px 0 30px 0"}}>
                            <a href={"/datasets/" + this.props.info.id.toString()}>
                                <img src={this.props.info.picture_url} alt="dataset thumbnail" className="dataset-pictures" style={{height: '100px', width: '100px'}} />
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div style={{padding: '15px 0 30px 0'}}>
                            <a href={"/datasets/" + this.props.info.id.toString()} className="datasetRowLink">
                                <h2>{capitalizeHyphenated(this.props.info.name, "_")}</h2>
                                <p style={{fontSize: '110%'}}>{this.props.info.dataset_info['shortDesc']}</p>
                                <br/>
                                <Label color="green">
                                    <b>Rows:</b> {this.props.info.dataset_info['instances']}
                                </Label>
                                <Label color="green">
                                    <b>Columns:</b> {this.props.info.dataset_info['attributes']}
                                </Label>
                                <Label color="blue">
                                    <b>Tasks:</b> {this.props.info.dataset_info['tasks']}
                                </Label>
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <DatasetDownload fileURL={this.props.info.file_url}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default DatasetRow