import React, {Component} from 'react'
import { connect } from 'react-redux'
import UploadIndicator from "./Indicators/UploadIndicator";
import ReadingIndicator from './Indicators/ReadingIndicator'
import constants from '../../Constants/constants'
import {modifyStatus} from "../../Actions/ModifyStatus";
import * as XLSX from 'xlsx';
import getExtension from "../../Constants/getExtension";
import { Icon, Label } from 'semantic-ui-react'
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";
import {modifyFilePK} from "../../Actions/ModifyFilePK";

class UploadFilesIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: null
        };

        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.updateSelection = this.updateSelection.bind(this);
        // this.getStatusAndResults = this.getStatusAndResults.bind(this);
    }

    updateSelection(file){
        const extension = getExtension(file.name)
        if (extension === "csv" || extension === "txt"){
            const Papa = require('papaparse/papaparse.min');
            Papa.parse(file, {
                preview: 1000,
                complete: (results, file) => {
                    this.props.modifyFilePK(file.name)
                    this.props.modifyFileInformation(results.data)
                    this.props.modifyStatus("read");
                }
            })

        }
        else{
            var reader = new FileReader();
            // var name = f.name;
            reader.onload = (e) => {
                const initData = new Uint8Array(e.target.result);
                const workbook = XLSX.read(initData, {type: 'array', sheetRows: 1000});
                const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const data = XLSX.utils.sheet_to_json(first_worksheet, {header:1});

                this.props.modifyFilePK(file.name)
                this.props.modifyFileInformation(data);
                this.props.modifyStatus("read");
            };
            reader.readAsArrayBuffer(file);
        }
    }

    handleFileUpload(e){
        const files = e.target.files;


        if (files.length === 1){
            this.props.modifyStatus("reading");

            this.props.modifyStatus("saved");
            this.updateSelection(files[0])
        }
    }


    render() {
        // Hides the Input Button
        const inputFileStyle = {
            width: '0.1px',
            height: '0.1px',
            opacity: '0',
            overflow: 'hidden',
            position: 'absolute',
            zIndex: '-1'
        };

        // Valid extensions for the file upload
        const acceptExtensions = constants.extensions.join(",");

        // Components for what status the job is in
        const indicators = {
            upload: <UploadIndicator />,
            reading: <ReadingIndicator percentage={0} />,
            saved: <ReadingIndicator percentage={45} />,
            read: <UploadIndicator />
        };

        return (
            <div className="dashboardDisplayIndex">
                <div id="uploadFilesBlock">
                    <input
                        type="file"
                        name="inputFileUpload"
                        id="inputFileUpload"
                        className="form-control-file"
                        onChange={this.handleFileUpload}
                        style={inputFileStyle}
                        multiple={false}
                        accept={acceptExtensions}
                    />
                    <div className="uploadOuterSection">
                        <label className="uploadInnerSection" htmlFor="inputFileUpload">
                            {indicators[this.props.status]}
                        </label>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    fileInformation: state.mainState.fileInformation,
    status: state.mainState.status,
    sheetRead: state.mainState.sheetRead
});

const mapActionsToProps = {
    modifyStatus: modifyStatus,
    modifyFileInformation: modifyFileInformation,
    modifyFilePK: modifyFilePK
};

export default connect(mapStateToProps, mapActionsToProps)(UploadFilesIndex)