import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyInputColumns} from "../../Actions/ModifyInputColumns";
import {modifyPredictorColumn} from "../../Actions/ModifyPredictorColumn";
import { Dropdown, Checkbox, Button, Icon } from 'semantic-ui-react'

class SelectIndex extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.props.modifyPredictorColumn(this.props.fileInformation[0].length - 1)

        const defaultInputColumns = this.props.fileInformation[0].filter((x, idx) => idx !== this.props.fileInformation[0].length - 1).map((x, idx) => idx);

        this.props.modifyInputColumns(defaultInputColumns)
    }

    handleDropdownChange = (e, {value}) => {
        const copiedInputColumns = JSON.parse(JSON.stringify(this.props.inputColumns))
        if (copiedInputColumns.includes(value)){
            copiedInputColumns.splice(copiedInputColumns.indexOf(value), 1)
            this.props.modifyInputColumns(copiedInputColumns)
        }
        this.props.modifyPredictorColumn(value)
    }

    isCheckboxChecked = (idx) => { return this.props.inputColumns.includes(idx) }

    handleCheckboxChange = (idx) => {
        const copiedInputColumns = JSON.parse(JSON.stringify(this.props.inputColumns))
        if (copiedInputColumns.includes(idx)){
            copiedInputColumns.splice(copiedInputColumns.indexOf(idx), 1)
        } else {
            copiedInputColumns.push(idx)
        }

        this.props.modifyInputColumns(copiedInputColumns)
    }

    changeStep = (step) => this.props.changeStep(step)

    render() {
        const columnNames = this.props.fileInformation[0];
        const dropdownOptions = columnNames.map((x, idx) => { return { key:idx, value: idx, text: x } })

        let inputColumnSelections = [];

        for (let i=0; i<columnNames.length; i++){
            if (i !== this.props.predictColumnIdx){
                inputColumnSelections.push(
                    <div key={i.toString()}>
                        <Checkbox checked={this.isCheckboxChecked(i)}
                                  label={columnNames[i]}
                                  onChange={(e) => this.handleCheckboxChange(i)}
                        />
                    </div>)
            }
        }

        return (
            <div>
                <div className="row">
                    <div className="col-sm-3">
                        <Button color="yellow"
                                fluid
                                icon="left arrow"
                                content="Explore some more"
                                onClick={(e) => this.changeStep(0)}/>
                    </div>
                    <div className="col-sm-5">
                    </div>
                    <div className="col-sm-4">
                        <Button color="orange" disabled={this.props.predictColumnIdx === -1 || this.props.inputColumns.length === 0} fluid onClick={e => this.changeStep(2)}>
                            Select Model Hyperparameters
                            <Icon name="arrow right" />
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4" style={{borderRight: "1px solid lightgrey", padding: "25px 25px 50px 25px"}}>
                        <h4>I want to predict...</h4>
                        <Dropdown
                            fluid
                            value={this.props.predictColumnIdx}
                            placeholder="Select Column to Predict"
                            options={dropdownOptions}
                            selection
                            onChange={this.handleDropdownChange}
                        />
                    </div>
                    <div className="col-sm-8" style={{padding: "25px 25px 50px 25px"}}>
                        <h4>I want to use these Variables to predict</h4>
                        {inputColumnSelections}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    inputColumns: state.mainState.inputColumns,
    predictColumnIdx: state.mainState.predictColumnIdx
})

const mapActionsToProps = {
    modifyInputColumns: modifyInputColumns,
    modifyPredictorColumn: modifyPredictorColumn
}

export default connect(mapStateToProps, mapActionsToProps)(SelectIndex)