import React from 'react'
import { Route, Switch } from "react-router";
import LandingPageIndex from './LandingPage/LandingPageIndex'
import NotFound from './InfoPages/NotFound'
import PrivacyPolicy from './InfoPages/PrivacyPolicy'
import TermsAndConditions from './InfoPages/TermsAndConditions'
import LoginIndex from './Authentication/UserPages/LogIn/LoginIndex'
import SignUpIndex from './Authentication/UserPages/SignUp/SignUpIndex'
import ResetPasswordIndex from './Authentication/UserPages/Password/ResetPasswordIndex'
import HelpIndex from "./InfoPages/HelpIndex"
import PricingPage from "./InfoPages/Pricing/PricingPage"
import DatasetsIndex from "./InfoPages/Datasets/DatasetsIndex";
import DatasetSpecificIndex from "./InfoPages/Datasets/DatasetSpecificIndex";

export default ({ childProps }) =>
    <Switch>
        <Route exact path='/' component={LandingPageIndex} />
        <Route exact path='/FAQs' component={HelpIndex} />
        <Route exact path='/terms' component={TermsAndConditions} />
        <Route exact path='/datasets' component={DatasetsIndex}/>
        <Route exact path="/datasets/:id" component={DatasetSpecificIndex} />
        <Route exact path='/privacy' component={PrivacyPolicy} />
        <Route component={NotFound}/>

    </Switch>;