import React, {Component} from 'react'
import API_Root from "../../Constants/API_Root"
import { Popup, Button } from 'semantic-ui-react'

class FileSummaryBaseURL extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popupContent: "Copy URL"
        }

        this.copyURL = this.copyURL.bind(this);
    }

    copyURL(e){
        this.baseURLRef.select();
        document.execCommand("copy")
        window.getSelection().removeAllRanges();
        this.setState({popupContent: "Copied!"})
        setTimeout(() => this.setState({ popupContent: "Copy URL" }), 2000)
    }

    render() {
        const API = API_Root === "https://api-woyera.com/" ? "https://api.apispreadsheets.com/" : API_Root;
        // const API = "https://api.apispreadsheets.com/";
        const url = API + this.props.url;
        return (
            <div className="fileDesc">
                <div className="fileDescDiv">
                    <div className="fileDescTextArea">
                        <input readOnly={true} style={{width: '75%'}} value={url} type="text" ref={(input) => {this.baseURLRef = input }} />
                    </div>
                    <div className="fileDescButtons">
                        <Popup content={this.state.popupContent} trigger={<Button circular
                                                                                  icon="copy"
                                                                                  color="olive"
                                                                                  inverted size="mini"
                                                                                  onClick={this.copyURL} />}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default FileSummaryBaseURL