import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Button, Form, Dropdown} from 'semantic-ui-react'
import {modifyAlgorithmParams} from "../../Actions/ModifyAlgorithmParams";
import ParameterInput from './ParameterInput'

class DecisionTreeClassification extends Component {
    constructor(props) {
        super(props);

        this.state = {
                params: {
                        criterion: 'gini',
                        splitter: 'best',
                        max_depth: null,
                        min_samples_split: 2,
                        min_samples_leaf: 1,
                        min_weight_fraction_leaf: 0.0,
                        max_features: null,
                        random_state: 0,
                        max_leaf_nodes: null,
                        min_impurity_decrease: 0.0,
                        min_impurity_split: null,
                        class_weight: null,
                        presort: false
                },
                defaultValues: {
                        criterion: 'gini',
                        splitter: 'best',
                        max_depth: null,
                        min_samples_split: 2,
                        min_samples_leaf: 1,
                        min_weight_fraction_leaf: 0.0,
                        max_features: null,
                        random_state: null,
                        max_leaf_nodes: null,
                        min_impurity_decrease: 0.0,
                        min_impurity_split: null,
                        class_weight: null,
                        presort: false
                },
            };

        this.restoreDefaults = this.restoreDefaults.bind(this);
        this.props.modifyAlgorithmParams(this.state.params);

        this.handleCriterionDropdown = this.handleCriterionDropdown.bind(this);
        this.handleSplitterDropdown = this.handleSplitterDropdown.bind(this);

        this.updateParameterInput = this.updateParameterInput.bind(this);
    }

    handleCriterionDropdown(event, {value}){
        let params = this.state.params;

        params['criterion'] = value;

        this.setState({params: params});
        this.props.modifyAlgorithmParams(this.state.params);
    }

    handleSplitterDropdown(event, {value}){
        let params = this.state.params;

        params['splitter'] = value;

        this.setState({params: params});
        this.props.modifyAlgorithmParams(this.state.params);
    }

    restoreDefaults(){
        this.setState({params: this.state.defaultValues});
        this.props.modifyAlgorithmParams(this.state.defaultValues);
    }

    updateParameterInput(name, value){
        let params = this.state.params;

        params[name] = value;

        this.setState({params: params});
        this.props.modifyAlgorithmParams(this.state.params);
    }

    render() {
        const criterionDropdownOptions = [
            { key: 1, text: 'Gini Impurity', value: "gini"},
            { key: 2, text: 'Entropy', value: "entropy"}
        ];

        const splitterDropdownOptions = [
            { key: 1, text: 'Best Split', value: "best"},
            { key: 2, text: 'Random Split', value: "random"},
        ];

        return (
            <div>
                <Form>
                    <Form.Field>
                        <label>Error Criterion</label>
                        <Dropdown placeholder="Select Error Criterion"
                            name="criterionSelection"
                            value={this.state.params.criterion}
                            options={criterionDropdownOptions}
                            onChange={this.handleCriterionDropdown}
                            selection
                            />
                    </Form.Field>
                    <Form.Field>
                        <label>Splitter</label>
                        <Dropdown placeholder="Select Splitter"
                            name="splitterSelection"
                            value={this.state.params.splitter}
                            options={splitterDropdownOptions}
                            onChange={this.handleSplitterDropdown}
                            selection
                            />
                    </Form.Field>
                    <Form.Field>
                        <label>Max Depth</label>
                        <ParameterInput
                            types={["int", "null"]}
                            restrictions={{int: [null, null]}}
                            info="The maximum depth of the tree. If None, then nodes are expanded until all leaves are pure or until all leaves contain less than min_samples_split samples"
                            onUpdateValue={this.updateParameterInput}
                            name="max_depth"
                            paramValue={this.state.params.max_depth}
                        />
                    </Form.Field>
                </Form>
                <Button onClick={this.restoreDefaults}>Restore Defaults</Button>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    mlHyperParameters: state.mainState.mlHyperParameters,
    predictionType: state.mainState.predictionType
});

const mapActionsToProps = {
    modifyAlgorithmParams: modifyAlgorithmParams
};

export default connect(mapStateToProps, mapActionsToProps)(DecisionTreeClassification)