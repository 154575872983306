import {reshapeData} from "../../../Constants/reshapeData";

const getColumnType = (columnValues) => {
    let numericalCount = 0;
    for (let i=0; i<columnValues.length; i++){
        if (!isNaN(columnValues[i]) ){
            numericalCount += 1
        }
    }

    if (numericalCount === columnValues.length){
        return "numerical"
    } else {
        return "categorical"
    }
}


export const labelEncodeData = (data) => {
    const reshapedData = reshapeData(data);
    const labelClasses = reshapedData[reshapedData.length - 1].filter((x, idx, a) => a.indexOf(x) === idx)

    const colTypes = [];
    const labelMappings = [];

    // Label Encode values in columns
    for (let col=0; col<reshapedData.length; col++){
        const colType = getColumnType(reshapedData[col])
        colTypes.push(colType)

        // Get label encode mapping
        const labelEncodeMapping = {};
        const labelEncodeValues = [];

        // if (colType === "categorical"){
        for (let row=0; row < reshapedData[col].length; row++){
            const cellValue = reshapedData[col][row];

            if (colType === "categorical") {
                if (cellValue in labelEncodeMapping) {
                    reshapedData[col][row] = labelEncodeMapping[cellValue]
                } else {
                    const encodeValue = labelEncodeValues.length

                    labelEncodeMapping[cellValue] = encodeValue
                    labelEncodeValues.push(encodeValue)

                    reshapedData[col][row] = encodeValue
                }
            } else {
                reshapedData[col][row] = parseFloat(cellValue)
            }
        }

        labelMappings.push(labelEncodeMapping)
    }

    // Get min-max and normalize
    for (let col=0; col<reshapedData.length - 1; col++){
        let min = Math.min(...reshapedData[col]);
        let max = Math.max(...reshapedData[col]);

        const colType = colTypes[col]

        for (let row=0; row < reshapedData[col].length; row++){
            const cellValue = reshapedData[col][row];

            // If cat normalize between 1-2 else 0-1
            let a;
            let b;
            if (colType === "categorical"){
                a = 1;
                b = 2;
            } else {
                a = 0;
                b = 1;
            }

            let normValue;
            if (max - min === 0){
                normValue = a
            } else {
                normValue = a + (((cellValue - min) * (b - a)) / (max - min))
            }

            reshapedData[col][row] = normValue

        }
    }

    console.log(JSON.parse(JSON.stringify(reshapedData[reshapedData.length - 1])))

    return {
        data: reshapeData(reshapedData, false),
        labelClasses: labelClasses,
        labelMappings: labelMappings
    }
}