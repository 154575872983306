export const MODIFY_FILE_INFORMATION = 'MODIFY_FILE_INFORMATION';

export function modifyFileInformation(fileInformation){
    return{
        type: MODIFY_FILE_INFORMATION,
        mainState: {
            fileInformation: removeBlankValues(fileInformation)
        }
    }
}

const removeBlankValues = (data) => {
    let cleanedData = null;
    if (Array.isArray(data)){
        cleanedData = [];
        if (data.length > 1){
            let colLength = data[0].length;
            for (let i=0; i<data.length; i++){
                if (data[i].length === colLength){
                    cleanedData.push(data[i])
                }
            }
        }
    }

    return cleanedData
}