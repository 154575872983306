import React, {Component} from 'react'
import AuthUserContext from '../../Authentication/AuthUserContext';
import NavbarAuth from './NavbarAuth'
import NavbarNonAuth from './NavbarNonAuth'
import { isMobile } from 'react-device-detect'
import NavbarMobile from '../Navbar/NavbarMobile'

class NavbarIndex extends Component {
    render() {
        if (isMobile){
            return(
                <NavbarMobile />
            )
        }
        return (
            <div>
                <AuthUserContext.Consumer>
                    {  authUser => authUser
                        ? <NavbarAuth />
                        : <NavbarNonAuth />
                    }
                </AuthUserContext.Consumer>
            </div>
        )
    }
}

export default NavbarIndex