import {TOGGLE_MODAL} from "../Actions/ToggleModal";
import {MODIFY_FILE_PK} from "../Actions/ModifyFilePK";
import {MODIFY_FILE_INFORMATION} from "../Actions/ModifyFileInformation";
import {MODIFY_STATUS} from "../Actions/ModifyStatus";
import {MODIFY_EDIT_OPTIONS} from "../Actions/ModifyEditOptions";
import {RESET_FILE_STATE} from "../Actions/ResetFileState";
import {RESET_STATE_AFTER_UPLOAD} from "../Actions/ResetStateAfterUpload";
import {TOGGLE_PRO} from "../Actions/TogglePro";
import {MODIFY_CURRENCY} from "../Actions/ModifyCurrency";
import { MODIFY_TEST_SPLIT } from "../Actions/ModifyTestSplit";
import {MODIFY_ALGORITHM} from "../Actions/ModifyAlgorithm";
import {MODIFY_PREDICTOR_COLUMN} from "../Actions/ModifyPredictorColumn";
import {MODIFY_PREDICTION_TYPE} from "../Actions/modifyPredictionType";
import {MODIFY_ALGORITHM_PARAMS} from "../Actions/ModifyAlgorithmParams";
import {UPDATE_MODEL_ACCURACY} from "../Actions/UpdateModelAccuracy";
import {INIT_MODEL_PK} from "../Actions/InitModelPK";
import {MODIFY_PREDICT_MODEL} from "../Actions/ModifyPredictModel";
import {MODIFY_INPUT_COLUMNS} from "../Actions/ModifyInputColumns";


function mainState(
    state = {
        filePK: "",
        fileInformation: null,
        editOptions: {
            header: true,
            startRow: "",
            startRowError: false,
            delimiter: null,
        },
        modalName: null,
        status: "upload",
        pro: false,
        currency: "usd",
        testSplit: 20,
        predictColumnIdx: -1,
        inputColumns: [],
        mlAlgorithm: 'neuralNetwork',
        mlHyperParameters: {optimizer: "adam", loss: "categoricalCrossentropy", neuralNetwork: { layers: 1, units: 1}},
        mlStep: 1,
        predictionType: 'classification',
        modelAccuracy: {},
        modelPK: -1,
        predictModel: ''
    }, action
) {
    switch (action.type) {
        case MODIFY_FILE_PK:
            return Object.assign({}, state, {
                filePK: action.mainState.filePK
            });
        case MODIFY_FILE_INFORMATION:
            return Object.assign({}, state, {
                fileInformation: action.mainState.fileInformation
            });
        case MODIFY_EDIT_OPTIONS:
            return Object.assign({}, state, {
                editOptions: action.mainState.editOptions
            });
        case TOGGLE_MODAL:
            return Object.assign({}, state, {
                modalName: action.mainState.modalName
            });
        case MODIFY_STATUS:
            return Object.assign({}, state, {
                status: action.mainState.status
            });
        case TOGGLE_PRO:
            return Object.assign({}, state, {
                pro: action.mainState.pro
            });
        case MODIFY_CURRENCY:
            return Object.assign({}, state, {
                currency: action.mainState.currency
            });
        case RESET_FILE_STATE:
            return Object.assign({}, state, {
                filePK: -1,
                fileInformation: {},
                editOptions: {
                    header: true,
                    startRow: "",
                    startRowError: false,
                    delimiter: null,
                },
                modalName: null,
                status: "upload"
            });
        case MODIFY_TEST_SPLIT:
            return Object.assign({}, state, {
                testSplit: action.mainState.testSplit
            });
        case MODIFY_ALGORITHM:
            return Object.assign({}, state, {
                mlAlgorithm: action.mainState.mlAlgorithm
            });
        case MODIFY_PREDICTOR_COLUMN:
            return Object.assign({}, state, {
                predictColumnIdx: action.mainState.predictColumnIdx
            });
        case MODIFY_PREDICTION_TYPE:
            return Object.assign({}, state, {
                predictionType: action.mainState.predictionType
            });
        case MODIFY_ALGORITHM_PARAMS:
            return Object.assign({}, state, {
                mlHyperParameters: action.mainState.mlHyperParameters
            });
        case UPDATE_MODEL_ACCURACY:
            return Object.assign({}, state, {
                modelAccuracy: action.mainState.modelAccuracy
            });
        case INIT_MODEL_PK:
            return Object.assign({}, state, {
                modelPK: action.mainState.modelPK
            });
        case MODIFY_PREDICT_MODEL:
            return Object.assign({}, state, {
                predictModel: action.mainState.predictModel
            });
        case MODIFY_INPUT_COLUMNS:
            return Object.assign({}, state, {
                inputColumns: action.mainState.inputColumns
            });
        case RESET_STATE_AFTER_UPLOAD:
            return Object.assign({}, state, {
                editOptions: action.mainState.editOptions,
                sheetRead: action.mainState.sheetRead,
                selectedColumns: action.mainState.selectedColumns,
                cleanOptionType: action.mainState.cleanOptionType,
                customOptions: action.mainState.customOptions,
                cleanResults: action.mainState.cleanResults,
                cleanResultsOptions: action.mainState.cleanResultsOptions
            });
        default:
            return state
    }
}

export default mainState;

