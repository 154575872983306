import React, {Component} from 'react'

class ModelSelector extends Component {
    constructor(props) {
        super(props);

        this.changeNewModel = this.changeNewModel.bind(this);
    }

    changeNewModel = (e, newModel) => {
        this.props.changeNewModel(newModel)
    }

    render() {
        const divStyle = {
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)",
            textAlign: "center",
            fontSize: "20px",
            padding: "20px",
            cursor: "pointer"
        };

        const newDiv = JSON.parse(JSON.stringify(divStyle));
        const oldDiv = JSON.parse(JSON.stringify(divStyle));

        if (this.props.newModel){
            newDiv['backgroundColor'] = "grey";
            newDiv['color'] = "white";
        } else {
            oldDiv['backgroundColor'] = "grey";
            oldDiv['color'] = "white";
        }

        return (
            <div className="row">
                <div className="col-sm-2" />
                <div className="col-sm-3">
                    <div
                        style={newDiv}
                        onClick={(e) => this.changeNewModel(e, true)}
                    >
                        Create New Model
                    </div>
                </div>
                <div className="col-sm-2" />
                <div className="col-sm-3">
                    <div
                        style={oldDiv}
                        onClick={(e) => this.changeNewModel(e, false)}
                    >
                        Predict from Model
                    </div>
                </div>
                <div className="col-sm-2" />
            </div>
        )
    }
}

export default ModelSelector