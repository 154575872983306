import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyAlgorithmParams} from "../../Actions/ModifyAlgorithmParams";
import {Button} from 'semantic-ui-react'
import DecisionTreeRegression from './DecisionTreeRegression'
import DecisionTreeClassification from './DecisionTreeClassification'

class DecisionTreeParams extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showParams: false
        };

        this.showParams = this.showParams.bind(this);
    }

    showParams(type, event){
        if (type === "minus"){
            this.setState({showParams: false})
        }
        else{
            this.setState({showParams: true})
        }
    }

    render() {
        let algorithmParameters = null;

        if (this.props.predictionType === "classification"){
            algorithmParameters = <DecisionTreeClassification />
        }
        else{
            algorithmParameters = <DecisionTreeRegression />
        }

        if (this.state.showParams){
            return (
                <div>
                    <Button circular icon="minus"
                            color="blue"
                            onClick={(e) => this.showParams("minus", e)} />
                    <p>Hide Parameters</p>
                    {algorithmParameters}
                </div>
            )
        }
        else{
            return (
                <div>
                    <Button circular icon="plus"
                            color="blue"
                            onClick={(e) => this.showParams("plus", e)} />
                    <p>Show Parameters</p>
                    <br/>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    mlHyperParameters: state.mainState.mlHyperParameters,
    predictionType: state.mainState.predictionType
});

const mapActionsToProps = {
    modifyAlgorithmParams: modifyAlgorithmParams
};

export default connect(mapStateToProps, mapActionsToProps)(DecisionTreeParams)