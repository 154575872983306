import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Button, Form, Dropdown} from 'semantic-ui-react'
import {modifyAlgorithmParams} from "../../Actions/ModifyAlgorithmParams";

class DecisionTreeRegression extends Component {
    constructor(props) {
        super(props);

        this.state = {
            params: {
                criterion: 'mse',
                splitter: 'best',
                max_depth: null,
                min_samples_split: 2,
                min_samples_leaf: 1,
                min_weight_fraction_leaf: 0.0,
                max_features: null,
                random_state: null,
                max_leaf_nodes: null,
                min_impurity_decrease: 0.0,
                min_impurity_split: null,
                presort: false
            },
            defaultValues: {
                criterion: 'mse',
                splitter: 'best',
                max_depth: null,
                min_samples_split: 2,
                min_samples_leaf: 1,
                min_weight_fraction_leaf: 0.0,
                max_features: null,
                random_state: null,
                max_leaf_nodes: null,
                min_impurity_decrease: 0.0,
                min_impurity_split: null,
                presort: false
            }
        };

        this.restoreDefaults = this.restoreDefaults.bind(this);
        this.props.modifyAlgorithmParams(this.state.params);

        this.handleCriterionDropdown = this.handleCriterionDropdown.bind(this);
        this.handleSplitterDropdown = this.handleSplitterDropdown.bind(this);
    }

    handleCriterionDropdown(event, {value}){
        let params = this.state.params;

        params['criterion'] = value;

        this.setState({params: params});
        this.props.modifyAlgorithmParams(this.state.params);
    }

    handleSplitterDropdown(event, {value}){
        let params = this.state.params;

        params['splitter'] = value;

        this.setState({params: params});
        this.props.modifyAlgorithmParams(this.state.params);
    }

    restoreDefaults(){
        this.setState({params: this.state.defaultValues});
        this.props.modifyAlgorithmParams(this.state.defaultValues);
    }

    render() {
        const criterionDropdownOptions = [
            { key: 1, text: 'Mean Squared Error', value: "mse"},
            { key: 2, text: 'Friedman\'s Improvement Score', value: "friedman_mse"},
            { key: 3, text: 'Mean Absolute Error', value: "mae"},
        ];

        const splitterDropdownOptions = [
            { key: 1, text: 'Best Split', value: "best"},
            { key: 2, text: 'Random Split', value: "random"},
        ];

        return (
            <div>
                <Form>
                    <Form.Field>
                        <Dropdown placeholder="Select Error Criterion"
                            name="criterionSelection"
                            value={this.state.params.criterion}
                            options={criterionDropdownOptions}
                            onChange={this.handleCriterionDropdown}
                            selection
                            />
                    </Form.Field>
                    <Form.Field>
                        <Dropdown placeholder="Select Splitter"
                            name="splitterSelection"
                            value={this.state.params.splitter}
                            options={splitterDropdownOptions}
                            onChange={this.handleSplitterDropdown}
                            selection
                            />
                    </Form.Field>
                </Form>
                <Button onClick={this.restoreDefaults}>Restore Defaults</Button>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    mlHyperParameters: state.mainState.mlHyperParameters,
    predictionType: state.mainState.predictionType
});

const mapActionsToProps = {
    modifyAlgorithmParams: modifyAlgorithmParams
};

export default connect(mapStateToProps, mapActionsToProps)(DecisionTreeRegression)