import React, {Component} from 'react'

class PredictModelIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                Predict Model Index
            </div>
        )
    }
}

export default PredictModelIndex