export const reshapeData = (data, rowToCol=true) => {
    let reshapedData = [];

    if (data.length > 0){
        if (rowToCol) {
            for (let col=0; col<data[0].length; col++){
                reshapedData.push([data[0][col]])
            }

            if (data.length > 1){
                for (let row=1; row<data.length; row++){
                    for (let col=0; col<data[row].length; col++){
                        const currData = JSON.parse(JSON.stringify(reshapedData[col]))
                        currData.push(data[row][col])
                        reshapedData[col] = currData
                    }
                }
            }
        } else {
            for (let row=0; row<data[0].length; row++){
                reshapedData.push([data[0][row]])
            }

            for (let col=1; col<data.length; col++){
                for (let row=0; row<data[col].length; row++){
                    const currRow = JSON.parse(JSON.stringify(reshapedData[row]))
                    currRow.push(data[col][row])
                    reshapedData[row] = currRow
                }
            }
        }
    }

    return reshapedData
}