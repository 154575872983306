import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from '../../SharedComponents/Modal'
import DecisionTreeParams from './DecisionTreeParams'

class AdditionalParamModal extends Component {
    constructor(props) {
        super(props);

        this.capitalizeModalHeader = this.capitalizeModalHeader.bind(this);
    }

    capitalizeModalHeader(){
        const algorithmType = this.props.mlAlgorithm;

        let capitalizedHeader;

        if (algorithmType === "kNN" || algorithmType === "svm" || algorithmType === ''){
            const headers = {
                kNN: "k-Nearest Neighbors (kNN)",
                svm: "Support Vector Machine (SVM)",
                '': "No Algorithm Selected"
            };

            capitalizedHeader = headers[algorithmType];
        }
        else{
            let inputString = algorithmType;
            capitalizedHeader = algorithmType[0].toUpperCase();

            for(let i=1; i<inputString.length; i++){
                if(inputString[i].match(/[A-Z]/) != null){
                    capitalizedHeader += " " + inputString[i]
                }
                else{
                    capitalizedHeader += inputString[i]
                }
            }
        }

        return capitalizedHeader + " - All Parameters"
    }

    render() {
        const algoParams = {
            decisionTree: <DecisionTreeParams/>,
            '': null
        };

        const modalHeader = this.capitalizeModalHeader();

        // const mlAlgorithmChoices = [
        //     { key: 1, text: 'Decision Trees', value: "decisionTree"},
        //     { key: 2, text: 'k-Nearest Neighbors', value: "kNN"},
        //     { key: 3, text: 'Linear Regression', value: "linearRegression"},
        //     { key: 4, text: 'Logistic Regression', value: "logisticRegression"},
        //     { key: 5, text: 'Neural Network', value: "neuralNetwork"},
        //     { key: 6, text: 'Support Vector Machine', value: "svm"},
        //     { key: 7, text: 'AdaBoost', value: "adaBoost"},
        // ];
        const modalBody = algoParams[this.props.mlAlgorithm];

        return (
            <ModalApp   name="additionalParamModal"
                        body={modalBody}
                        size="large"
                        header={null}
                   />
        )
    }
}

const mapStateToProps = (state) => ({
    mlAlgorithm: state.mainState.mlAlgorithm
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(AdditionalParamModal)