import React, {Component} from 'react'
import NewModelIndexSteps from "./NewModelIndexSteps";
import ExploreIndex from "./ExploreIndex"
import SelectIndex from "./SelectIndex"
import TrainIndex from "./TrainIndex"
import AssessIndex from "./AssessIndex"

class NewModelIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0
        };

        this.changeStep = this.changeStep.bind(this);
    }

    changeStep(step){
        this.setState({ step: step })
    }

    render() {
        const index = [<ExploreIndex changeStep={this.changeStep}/>,
            <SelectIndex changeStep={this.changeStep} />,
            <TrainIndex changeStep={this.changeStep}/>,
            <AssessIndex changeStep={this.changeStep} />]

        return (
            <div>
                <NewModelIndexSteps step={this.state.step} changeStep={this.changeStep}/>
                <div style={{padding: "50px 0 50px 0"}}>
                    {index[this.state.step]}
                </div>
            </div>
        )
    }
}

export default NewModelIndex