import React, {Component} from 'react'
import {CardElement, injectStripe} from 'react-stripe-elements';
import API_Root from "../../Constants/API_Root";
import { Input, Message, Button } from 'semantic-ui-react'
import { auth } from '../../Authentication/firebase';
import isEmailValid from '../../Constants/isEmailValid'
import AuthComponent from "../AuthComponent";

class CreditCardDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            passwordOne:'',
            tandc: false,
            cardSuccess: null,
            name: '',
            submitDisabled: true,
            loading: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.submit = this.submit.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
        this.signUp = this.signUp.bind(this);
        this.emailValid = this.emailValid.bind(this);
        this.passwordOneValid = this.passwordOneValid.bind(this);
        this.updateLocalStorage = this.updateLocalStorage.bind(this);

    }

    dismissMessage(){
        this.setState({ cardSuccess: null })
    }

    handleInputChange(e){
        let elementName = e.target.name;

        if (elementName === "tandc"){
            this.setState({
                tandc: !this.state.tandc
            })
        }
        else{
            let elementValue = e.target.value;

            if (elementName === "email" || elementName === "name"){
                this.setState({
                    [elementName]: (elementValue).trim()
                })
            }
            else{
                this.setState({
                    [elementName]: elementValue
                })
            }
        }
    }

    updateLocalStorage(email, token){
        return new Promise((resolve, reject) => {
            let updated;
            try{
                localStorage.setItem("email", email)
                localStorage.setItem("token", token)
                updated = true
            }
            catch(error){
                updated = false
            }
            resolve(updated)
        })
    }

    signUp(e){
        this.setState({
            loading: true
        })

        const email = this.state.email;
        const passwordOne = this.state.passwordOne;

        const API = API_Root + 'api/save-user/'; /*save user email to our db*/
        const formData = {
            "email": email,
            "username": email,
            "password": passwordOne
        };


        // localStorage.setItem("email", email);
        // localStorage.setItem("token", data.token);

        const serverErrorMessage = <Message error
                                            onDismiss={this.dismissMessage}
                                            header="There was an error on our end creating the account"
                                            content={<span>Please <a href={"mailto:help@splitspreadsheets.com?Subject=Create%20Account%20Error%20for%20" + email}>Email
                                                Us</a></span>}
        />

        auth.doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
                this.setState(() => ({
                    username: '',
                    email: '',
                    passwordOne: '',
                    passwordTwo: '',
                    tandc: false,
                    error: null,
                }));
                fetch(API, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                })
                    .then((res) => {
                        if (res.status === 200) {
                            res.json().then((data) => {
                                const updateLocalStorage = this.updateLocalStorage(email, data.token);

                                updateLocalStorage.then(
                                    (success) => {
                                        if (success){
                                            this.submit(e)
                                        }
                                        else{
                                            this.setState({
                                                cardSuccess: serverErrorMessage,
                                                loading: false
                                            })
                                        }
                                })
                            }).catch(error => this.setState({cardSuccess: serverErrorMessage, loading: false}))
                        }
                    }).catch(error => this.setState({cardSuccess: serverErrorMessage, loading: false}))

            }).catch(error => {
                this.setState({cardSuccess: <Message error
                                                     onDismiss={this.dismissMessage}
                                                     header={error.message}
                                                     content="Please Try Again!"
                    /> , loading: false});
            });
    }

    async submit(e) {
        this.setState({
            loading: true
        })

        const failedMessage = "Your Payment Failed!";
        let {token} = await this.props.stripe.createToken({name: "Name"});

        if (token !== undefined){
            let response = await fetch(API_Root + "api/charge-merge-spreadsheets/", {
                method: "POST",
                headers: {"Content-Type": "text/plain"},
                body: JSON.stringify({
                    stripeToken: token.id,
                    email: localStorage.getItem("email"),
                    name: this.state.name,
                    token: localStorage.getItem("token"),
                    currency: this.props.currency
                })
            }).then().catch(err =>
                this.setState({
                    loading: false,
                    cardSuccess: <Message error
                                          onDismiss={this.dismissMessage}
                                          header={failedMessage}
                                          content="Please try again!"
                    />
                })
            );

            if (response !== undefined){
                if (response.ok){
                    this.setState({
                        loading: false,
                        cardSuccess: <Message positive={true}
                                              onDismiss={this.dismissMessage}
                                              header="Your payment was a success!"
                        />
                    })

                    this.props.changeProcess("completed")
                }
                else{
                    this.setState({
                        loading: false,
                        cardSuccess: <Message error
                                              onDismiss={this.dismissMessage}
                                              header={failedMessage}
                                              content="Please try again!"
                        />
                    })
                }
            }

        }
        else{
            this.setState({
                loading: false,
                cardSuccess: <Message error
                                      onDismiss={this.dismissMessage}
                                      header={failedMessage}
                                      content="Please enter all details and try again!"
                />
            })
        }
    }

    emailValid(){
        if (this.state.email.trim() !== ""){
            return isEmailValid(this.state.email)
        }
        else{
            return true
        }
    }

    passwordOneValid(){
        if (this.state.passwordOne.trim() !== ""){
            return this.state.passwordOne.length >= 8
        }
        else{
            return true
        }
    }

    render() {
        const {
            email,
            passwordOne,
            tandc
        } = this.state;

        const isInvalid = passwordOne.trim() === '' || passwordOne.length < 8 || email.trim() === ''|| tandc === false || !isEmailValid(email.trim());
        const emailError = !this.emailValid() ? <small className="inputError">Enter a valid email</small> : null;
        const passwordOneError = !this.passwordOneValid() ? <small className="inputError">Password must be at least 8 characters</small> : null;

        return (
            <div className="checkout">
                <AuthComponent authComponent={null}
                    nonAuthComponent={
                        <div>
                        {/*<Input icon="user"*/}
                               {/*iconPosition='left'*/}
                               {/*name="name"*/}
                               {/*placeholder="Name on Card"*/}
                               {/*onChange={this.handleInputChange} value={this.state.name} fluid />*/}
                        {/*<br/>*/}
                        <Input  icon="at"
                            iconPosition='left'
                            type="email" name="email"
                            placeholder="Account Email"
                            onChange={this.handleInputChange}
                            error={!this.emailValid()}
                            value={email} fluid/>
                        {emailError}
                        <br/>
                        <Input  icon="lock"
                            iconPosition="left"
                            type="password" name="passwordOne"
                            placeholder="Enter a Password"
                            onChange={this.handleInputChange}
                            error={!this.passwordOneValid()}
                            value={passwordOne} fluid />
                        {passwordOneError}
                        <br/>
                        </div>
                    }
                    />

                    <div className="semanticBorder">
                        <CardElement />
                    </div>
                    <br/>
                    <input type="checkbox" name="tandc" id="tandc" onChange={this.handleInputChange} checked={tandc} />
                    <label htmlFor="tandc">
                        <span style={{paddingLeft: '7px'}}>I agree with Split Spreadsheets'</span>
                        <a href="/terms" target="_blank"> Terms and Conditions</a>
                    </label>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: "20px"}}>
                        <AuthComponent
                            authComponent={
                                <Button loading={this.state.loading}
                                        onClick={this.submit}
                                        color="purple"
                                        style={{textAlign: 'center'}}>
                                    {"Upgrade to Pro for " + this.props.currencyAmount + " per month"}
                                    <span role="img" aria-label="smile" style={{paddingLeft: '7px'}}>😀</span>
                                </Button>
                            }
                            nonAuthComponent={
                                <Button loading={this.state.loading}
                                        onClick={this.signUp}
                                    // onClick={this.submit}
                                        color="purple"
                                        disabled={isInvalid}
                                        style={{textAlign: 'center'}}>
                                    {"Upgrade to Pro for " + this.props.currencyAmount + " per month"}
                                    <span role="img" aria-label="smile" style={{paddingLeft: '7px'}}>😀</span>
                                </Button>
                            }
                        />
                    </div>
                    <br/>
                    {this.state.cardSuccess}
            </div>
        )
    }
}

export default injectStripe(CreditCardDetails)